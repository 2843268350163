import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          center
        >
          <CFView textCenter column center mt="250px" w="100%">
            <CFView column center>
              <CFImage
                w="85%"
                src={mobileHeroText}
                alt="Cravings Brookswood Restaurant hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mv="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          center
        >
          <CFView column center h="650px">
            <CFView column center mt="100px">
              <CFImage
                h="250px"
                src={heroText}
                alt="Cravings Brookswood Restaurant hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="30px" ml="15px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
